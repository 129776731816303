<template>
  <div class="page-listings">
    <banner-1 
      title="Tradeshow Display Loaner Program" 
      linkTarget="/lead-time-history"
      linkText="Lead Time History"
      loadingText="Loading Lead Times...."
      :loading="loading"
      desc="Materials Request Form"
    />
    <div class="container">
      <div class="row">
        <div
          v-if="loading == true"
          class="col-xs-12 col-sm-12 col-12 col-lg-12"
        >
          <div class="flex-box-wrap">
            <div class="flex-internal">
              <atom-spinner
                :animation-duration="1000"
                :size="40"
                :color="'#575757'"
              />
              <p>Loading......</p>
            </div>
          </div>
        </div>


        <div v-else class="col-xs-12 col-sm-12 col-12 col-lg-12">
          <div class="mt-3 mb-3">
            <div class="listing">
              <h5>
                Spacesaver has a limited supply of trade show graphics and supporting display materials available for our Spacesaver Group partners use in promoting Spacesaver products and solutions.
              </h5>
              <hr />
              <p class="disclaimer">
                Requests should be submitted at least 45 days prior to the needed date, and will be reserved on a first-come, first-serve basis. Details on displays available can be requested via email: <a href="mailto:tradeshowrequests@spacesaver.com" class="link">tradeshowrequests@spacesaver.com</a>. Tradeshow displays and graphics must be returned in the same condition as they were received otherwise charges may incur. Tradeshow displays and graphics must be returned on or before the date listed on the request form. Thank you for representing Spacesaver!
              </p>
            </div>
          </div>








          <div class="mt-3 mb-3">
            <div class="listing">
              <!-- mobile lead times -->
              <h3 class data-config-id="header">
                Contact:
              </h3>
              <hr />
              <div class="row">
                <div class="col-xs-12 col-md-6 col-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1" class="ital"
                      >* Contact Name <small v-if="validate && !contactName" class="orange">This field is required.</small></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="Contact Name"
                      placeholder="Enter Contact Name"
                      v-model="contactName"
                    />
                  </div>

                  <div class="form-group">
                  <label for="exampleInputEmail1" class="ital">* Company <small v-if="validate && !companyName" class="orange">This field is required.</small></label>
                  <input
                    type="text"
                    class="form-control"
                    aria-describedby="SSC Company Name"
                    placeholder="Enter Company Name"
                    v-model="companyName"
                  />
                </div> 
                
           
               

              
          
        
         
                </div>
                <div class="col-xs-12 col-md-6 col-12">
                  <div class="form-group">
                  <label for="exampleInputEmail1" class="ital">* Contact Email <small v-if="validate && !contactEmail" class="orange">This field is required.</small></label>
                  <input
                    type="email"
                    class="form-control"
                    aria-describedby="Contact Email"
                    placeholder="Enter Contact Email"
                    v-model="contactEmail"
                  />
                </div> 
                <div class="form-group">
                  <label for="exampleInputEmail1" class="ital">* Contact Phone <small v-if="validate && !contactPhone" class="orange">This field is required.</small></label>
                  <input
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                    class="form-control"
                    aria-describedby="Contact Phone"
                    placeholder="Enter Contact Phone"
                    v-model="contactPhone"
                  />
                </div> 
                  
                

                            
                </div>
              </div>

              <br/>
              <h3 class data-config-id="header">
                Show:
              </h3>
              <hr />
              <div class="row">
                <div class="col-xs-12 col-md-6 col-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1" class="ital"
                      >* Show Name <small v-if="validate && !showName" class="orange">This field is required.</small></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="Show Name"
                      placeholder="Enter Show Name"
                      v-model="showName"
                    />
                  </div>

                  <div class="form-group">
                    <label for="exampleFormControlTextarea1" class="ital"
                      >* Show Date <small v-if="validate && !showDate" class="orange">This field is required.</small></label
                    >
                    <datepicker
                      format="MMM dd yyyy"
                      placeholder="Select Show Date"
                      v-model="showDate"
                      class="form-control"
                      wrapperClass="datepicker1"
                    ></datepicker>
                  </div>

                <div class="form-group">
                  <label for="exampleFormControlSelect1" class="ital">* Show Market <small v-if="validate && !showMarket" class="orange">This field is required.</small></label>
                  <select
                    v-model="showMarket"
                    class="form-control"
                    id="exampleFormControlSelect1"
                  >
                    <option
                      disabled
                      v-bind:value="null"
                      class="all-contractors"
                    >
                      Select a market
                    </option>

                    <option value="Agriculture">Agriculture</option>
                    <option value="Business">Business</option>
                    <option value="Education">Education</option>
                    <option value="Education K-12">Education K-12</option>
                    <option value="Government">Government</option>
                    <option value="Healthcare">Healthcare</option>
                    <option value="Library">Library</option>
                    <option value="Military">Military</option>
                    <option value="Museum">Museum</option>
                    <option value="Public Safety">Public Safety</option>             
                    <option value="Unknown">Unknown</option>
                  </select>
                </div>
                
           
               

              
          
        
         
                </div>
                <div class="col-xs-12 col-md-6 col-12">
                  
                    <div class="form-group">
                    <label for="exampleFormControlTextarea1" class="ital">* Requested Materials <small v-if="validate && !requestedMaterials" class="orange">This field is required.</small></label>
                    <p style="margin-top: 10px; margin-bottom: 12px; opacity: 80%;"><small>Please indicate backdrop, tabletop, graphics only or X-Stand banners</small></p>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="6"
                      placeholder="Enter Requested Materials..."
                      v-model="requestedMaterials"
                      required
                    ></textarea>

                    
                  </div>
                  
                

                            
                </div>
              </div>

              <!-- #################### -->

              
              <br/>
              <h3 class data-config-id="header">
                Shipping:
              </h3>
              <hr />
              <div class="row">
                <div class="col-xs-12 col-md-6 col-12">
                    <div class="form-group">
                    <label for="exampleFormControlTextarea1" class="ital" style="margin-bottom: 12px;">* Shipping Address <small v-if="validate && !shippingAddress" class="orange">This field is required.</small></label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="4"
                      placeholder="Enter Shipping Address..."
                      v-model="shippingAddress"
                      required
                    ></textarea>

                    
                  </div>
                </div>

                <div class="col-xs-12 col-md-6 col-12">
                    <div class="form-group">
                    <label for="exampleFormControlTextarea1" class="ital"
                      >* Delivery By <small v-if="validate && !deliveryBy" class="orange">This field is required.</small></label
                    >
                    <datepicker
                      format="MMM dd yyyy"
                      placeholder="Select Delivery Date"
                      v-model="deliveryBy"
                      class="form-control"
                      wrapperClass="datepicker1"
                    ></datepicker>
                  </div>
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1" class="ital"
                      >* Returned By <small v-if="validate && !returnedBy" class="orange">This field is required.</small></label
                    >
                    <datepicker
                      format="MMM dd yyyy"
                      placeholder="Select Return Date"
                      v-model="returnedBy"
                      class="form-control"
                      wrapperClass="datepicker1"
                    ></datepicker>
                  </div>
                </div>
            </div>
            <div style="display: flex;" class="search-btns">
                <button class="mlauto" @click="submitRequest">
                    Submit
                    <i class="material-icons">keyboard_arrow_right</i>
                  </button>
            </div>


            </div>
          </div>
        </div>
        <!-- end other column -->
      </div>
    </div>
    <!-- container -->
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Avatar from "vue-avatar";
import Datepicker from "vuejs-datepicker";
import leadtimesTour from "../components/Tours/leadtimesTour";
import Banner1 from "../components/Banners/banner1.vue";

const auth = {
	headers: {
		Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
		//Authorization: "Bearer " + wontworkqwd2iwedjqoewiudwo
	},
};

export default {
	components: {
        AtomSpinner,
    Avatar,
    Datepicker,
    leadtimesTour,
    Banner1
    },
	name: "Tradeshow",
	data() {
		return {
			ordermanageapi: process.env.VUE_APP_ORDER_MANAGE_API,
            loading: false,
            validate: false,
            contactName: null,
            companyName: null,
            contactEmail: null,
            contactPhone: null,
            showName:null,
            showDate: null,
            showMarket: null,
            requestedMaterials: null,
            shippingAddress: null,
            deliveryBy: null,
            returnedBy: null,
		};
	},
	methods: {
		firstLoad: function() {
			//this.loading = true;
			this.$store.commit("setActivePage", "order-manage");
		},
        submitRequest: function() {
            let data = {
                contactName: this.contactName,
                companyName: this.companyName,
                contactPhone: this.contactPhone,
                showName: this.showName,
                showDate: this.showDate, 
                showMarket: this.showMarket, 
                requestedMaterials: this.requestedMaterials,
                shippingAddress: this.shippingAddress, 
                deliveryBy: this.deliveryBy, 
                returnedBy: this.returnedBy
            }
			this.validate = true;
            
            if (
                !this.contactName || 
                !this.companyName || 
                !this.contactPhone || 
                !this.showName || 
                !this.showDate || 
                !this.showMarket || 
                !this.requestedMaterials || 
                !this.shippingAddress || 
                !this.deliveryBy || 
                !this.returnedBy
                ) {
                console.log("not");
                this.$noty.error("Please fill out all the required fields");
                
            } else {
                console.log("submitted");
            }

		},

	},
	computed: {

	},
	created() {
		this.$store.commit("showNavigation");

		// console.log(this.$route.path);
	},
	mounted() {
		this.firstLoad();
	},
};
</script>
<style scoped>
/*:-moz-full-screen
#todo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
}
*/
.listing {
  background-color: #fff;
  line-height: 1;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.disclaimer{
    line-height: 1.4;
    opacity: 80%;
    font-style: italic;
}
.ital{
    font-style: italic;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  max-height: 40px;
}
.search-btns .clear {
  margin-left: auto;
}
.search-btns .search {
  margin-left: 5px;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}
.orange{
    color: #f16232;
}
.link{
    color: #00b4b4;
}
</style>