<template>
  <div>
      <!--
    <div id="v-step-0">A DOM element on your page. The first step will pop on this element because its ID is 'v-step-0'.</div>
    <div id="v-step-1">A DOM element on your page. The second step will pop on this element because its ID is 'v-step-1'.</div>
    <div id="v-step-2">A DOM element on your page. The third and final step will pop on this element because its ID is 'v-step-2'.</div>
-->
    <v-tour name="leadtimesTour" :steps="steps" :callbacks="myCallbacks">
      <template slot-scope="tour">
    <transition name="fade">
      <v-step
        v-if="tour.steps[tour.currentStep]"
        :key="tour.currentStep"
        :step="tour.steps[tour.currentStep]"
        :previous-step="tour.previousStep"
        :next-step="tour.nextStep"
        :stop="tour.stop"
        :skip="tour.skip"
        :is-first="tour.isFirst"
        :is-last="tour.isLast"
        :labels="tour.labels"
      >
        <template v-if="tour.currentStep === 1">
          <div slot="actions">
            <button @click="tour.skip" class="v-step__button v-step__button-previous">Skip tour</button>
            <button @click="tour.previousStep" class="v-step__button v-step__button-previous">Previous step</button>
            <button @click="tour.stop" class="v-step__button v-step__button-skip">Got it!</button>
          </div>
        </template>
      </v-step>
    </transition>
  </template>
    </v-tour>
  </div>
</template>

<script>
  export default {
    name: 'leadtimes-tour',
    data () {
      return {
        steps: [
          {
            target: '#lead-step-1',  // We're using document.querySelector() under the hood
            header: {
              title: 'Lead Time by product',
            },
            content: `<p style="font-weight: 300;">This area allows you to look for a specific product</p>`,
            params: {
              placement: 'top-start', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
              enableScrolling: false
            }
          },
          {
            target: '#lead-step-2',
            header: {
              title: 'Lead Time date',
            },
            content: `
            <p style="font-weight: 300;">View the current lead time for the product</p>
            `,
             params: {
              placement: 'top-start', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
              enableScrolling: false
            }
          },
        ],
        myCallbacks: {
				  onSkip: this.customSkipStepCallback
			  },
      }
    },
    methods: {
      customSkipStepCallback: function () {
			  console.log('Hello');
        this.$store.commit("hideTour");
        this.$noty.success("The help tour has been disabled, you can enable it again in the top menu under your name!");
		  },
    },
    mounted: function () {
      this.$tours['leadtimesTour'].start();
    }

  }
</script>
<style>
.v-step {
    /*background: #F16231!important;*/
    background: #50596c;
    background: #5f6d7b!important;
    color: #fff;
    max-width: 320px;
    border-radius: 3px;
    -webkit-filter: drop-shadow(0 0 2px rgba(0,0,0,.5));
    filter: drop-shadow(0 0 2px rgba(0,0,0,.5));
    padding: 1rem;
    text-align: center;
    z-index: 10000;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.18);
}
.v-step__header {
    margin: -1rem -1rem .5rem;
    padding: .5rem;
    background-color: #454d5d;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

</style>
